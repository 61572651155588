import * as Sentry from '@sentry/nuxt'
const { public: { sentry } } = useRuntimeConfig()
const sentryIntegrations = [
    Sentry.replayIntegration()
]
if (sentry.environment === 'staging') {
    sentryIntegrations.push(
        // @ts-expect-error somehow the TS types are incorrectly recognizing the feedbackIntegration here, but it is working as expected
        Sentry.feedbackIntegration({
            colorScheme: 'system',
            buttonLabel: 'Feedback',
            triggerLabel: '',
            submitButtonLabel: 'Send Feedback',
            formTitle: 'Send Feedback',
            enableScreenshot: true,
            showBranding: false
        })
    )
}
Sentry.init({
    dsn: sentry.dsn,
    environment: sentry.environment,
    enabled: sentry.environment !== 'local',
    release: sentry.release,
    normalizeDepth: 10,
    integrations: sentryIntegrations,
    tracePropagationTargets: [
        /^https:\/\/api\.test/,
        /^https:\/\/api\.foodticket\.dev/,
        /^https:\/\/api\.foodticket\.io/
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 0.1
})
